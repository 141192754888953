import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BottomLine from "../../components/BottomLine/BottomLine";
import Carousel from "../../components/Carousel/Carousel";
import { useMetaMask } from "../../utility/hooks/useMetaMask";
import { formatTimestampToAMPM } from "../../utility/Utils";
import {
  getGameTypes,
  getCreatedGames,
  getReadyGames,
  getStartedGames,
  getInfo,
} from "../store";

//css
import "./index.css";
//images
import NextArrowImg from "../../assets/img/next.png";
import PrevArrowImg from "../../assets/img/prev.png";
import cardBgImg from "../../assets/img/card-bg.png";
import HeadLine from "../../components/HeadLine/HeadLine";

const activeTimeBeetween = () => {
  const nowtimestamp = Date.parse(Date()) / 1000;
  return formatTimestampToAMPM(nowtimestamp).initialSlideIndex;
};

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} src={NextArrowImg} onClick={onClick} alt="" />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} src={PrevArrowImg} onClick={onClick} alt="" />
  );
}

const Lobby = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contractAndAuth, unSigner, wallet, connectMetaMask } = useMetaMask();
  const [activeGames, setActiveGames] = useState([]);
  const [jonedGameStatus, setJonedGameStatus] = useState(false);

  const handleClickRoom = async (room) => {
    if (wallet.accounts.length > 0) {
      if (Number(room.activeGameCount) > 0 || jonedGameStatus) {
        navigate({
          pathname: "join",
          search: `?room=${room.id}&start=${room.startTime}&end=${room.endTime}&ap=${room.timeZone}`,
          // search: `?room=${room.id}`,
        });
      }
    } else {
      connectMetaMask();
    }
  };

  const setRooms = async () => {
    dispatch(getCreatedGames({ dispatch, unSigner }))
      .then((result) => {
        let rooms = [
          {
            id: 0,
            activeGameCount: 0,
            title: "12 - 2 AM",
            startTime: 12,
            endTime: 2,
            timeZone: "am",
          },
          {
            id: 1,
            activeGameCount: 0,
            title: "3 - 5 AM",
            startTime: 3,
            endTime: 5,
            timeZone: "am",
          },
          {
            id: 2,
            activeGameCount: 0,
            title: "6 - 8 AM",
            startTime: 6,
            endTime: 8,
            timeZone: "am",
          },
          {
            id: 3,
            activeGameCount: 0,
            title: "9 - 11 AM",
            startTime: 9,
            endTime: 11,
            timeZone: "am",
          },
          {
            id: 4,
            activeGameCount: 0,
            title: "12 - 2 PM",
            startTime: 12,
            endTime: 2,
            timeZone: "pm",
          },
          {
            id: 5,
            activeGameCount: 0,
            title: "3 - 5 PM",
            startTime: 3,
            endTime: 5,
            timeZone: "pm",
          },
          {
            id: 6,
            activeGameCount: 0,
            title: "6 - 8 PM",
            startTime: 6,
            endTime: 8,
            timeZone: "pm",
          },
          {
            id: 7,
            activeGameCount: 0,
            title: "9 - 11 PM",
            startTime: 9,
            endTime: 11,
            timeZone: "pm",
          },
        ];
        result.payload.createdGames.forEach((game) => {
          // console.log("startdate:", formatTimestampToAMPM(game.startDate));
          const gameSlideIndex = formatTimestampToAMPM(
            game.startDate
          ).initialSlideIndex;
          rooms[gameSlideIndex].activeGameCount =
            rooms[gameSlideIndex].activeGameCount + 1;
        });
        console.log(rooms);
        setActiveGames(rooms);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Set Rooms
  useEffect(() => {
    document.title = "Lobby - Jammy";
    if (unSigner.contract && activeGames.length === 0) {
      setRooms();
    }
  }, [unSigner.contract, activeGames]);

  //Contract Events
  useEffect(() => {
    if (unSigner.contract) {
      unSigner.contract.on("CardsAdded", async () => {
        console.log("#CardsAdded event was emmited");
        dispatch(getInfo({ dispatch, unSigner }));
      });

      unSigner.contract.on("CardsUpdated", async () => {
        console.log("#CardsUpdated event was emmited");
        dispatch(getInfo({ dispatch, unSigner }));
      });

      unSigner.contract.on("GameCreated", async () => {
        console.log("#GameCreated event was emmited");
        setRooms();
      });

      unSigner.contract.on("GameCancelled", async () => {
        console.log("#GameCancelled event was emmited");
        setRooms();
      });
    }
  }, [unSigner.contract]);

  //Set GameTypes & alet joined
  useEffect(() => {
    if (contractAndAuth.contract) {
      setJonedGameStatus(false);
      if (!sessionStorage.getItem("gameTypes")) {
        dispatch(getGameTypes({ dispatch }));
      }

      dispatch(getStartedGames({ dispatch, contractAndAuth, wallet }))
        .then((result) => {
          console.log("getStartedGames:", result.payload.startedGames);
          if (result.payload) {
            result.payload.startedGames.forEach(async (game) => {
              if (game.isJoined) {
                setJonedGameStatus(true);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      dispatch(getReadyGames({ dispatch, contractAndAuth, wallet }))
        .then((result) => {
          console.log("getReadyGames:", result.payload.readyGames);
          if (result.payload) {
            result.payload.readyGames.forEach(async (game) => {
              if (game.isJoined) {
                setJonedGameStatus(true);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      dispatch(getCreatedGames({ dispatch, unSigner, wallet }))
        .then((result) => {
          console.log("getCreatedGames:", result.payload.createdGames);
          if (result.payload) {
            result.payload.createdGames.forEach(async (game) => {
              if (game.isJoined) {
                setJonedGameStatus(true);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [contractAndAuth.contract, unSigner.contract]);

  return (
    <>
      <HeadLine />

      <div className="lobby-carousel-area">
        {jonedGameStatus === true && (
          <div className="isJoined">Joined Game Avaiable</div>
        )}

        <Carousel
          centerMode={true}
          initialSlide={activeTimeBeetween()}
          infinite={true}
          slidesToShow={3}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          carouselData={
            activeGames &&
            activeGames.map((item, index) => (
              <div className="item" key={index}>
                <span className="time">{item.title}</span>
                <span className="game-number">
                  {item.activeGameCount} Active Games
                </span>
                <img
                  onClick={() => handleClickRoom(item)}
                  src={cardBgImg}
                  alt=""
                />
              </div>
            ))
          }
        />
      </div>

      <BottomLine />
    </>
  );
};

export default Lobby;
